import React, {useState, useEffect, useRef} from "react"
import copyFormatted from "../util/copyFormatted"
import './dashboard.css'

const Form = ({ updateDetails }) => {

  const [name, setName] = useState('')
  const [title, setTitle] = useState('')
  const [phone, setPhone] = useState('')

  useEffect(() => {
    updateDetails({
      name: name,
      title: title,
      phone: phone
    })
    if(name.length === 0){
      setName('Your Name')
    }
    if(title.length === 0){
      setTitle('Your Title')
    }
    if(phone.length === 0){
      setPhone('+614 00 000 000')
    }
  }, [name, title, phone])

  return(
    <>
    <h3>Your details</h3>
    <div className="form">
      <input type="text" placeholder="Name" onChange={e => setName(e.target.value)}/>
      <input type="text" placeholder="Title" onChange={e => setTitle(e.target.value)}/>
      <input type="text" placeholder="Phone" onChange={e => setPhone(e.target.value)}/>
    </div>
    </>
  )
}

const Instructions = () => (
  <div className="instructions">
    <h3>How to install</h3>
    <ol>
      <li>Make sure you have this page open in Google Chrome.</li>
      <li>Enter your details above.</li>
      <li>Check the preview on the right hand side is correct.</li>
      <li>Press the button beneath the preview to copy the HTML signature to your clipboard.</li>
      <li>Paste the HTML as a new signature in your preferred mail client. For specific instructions on different mail clients Google “How to install HTML email signature” in your mail client and version.</li>
    </ol>
  </div>
)

const Signature = ({ name, title, phone }) => {

  const signatureEl = useRef(null)

  const [buttonActive, setButtonActive] = useState(false)

  const handleButtonClick = e => {
    let html = signatureEl.current.innerHTML
    copyFormatted(html)
    setButtonActive(true)
    setTimeout(() => setButtonActive(false), 2000)
  }

  const createLink = () => {
    return(
      {__html:
        `<a href='http://dkam.com/' style="text-decoration: none; text-decoration: none !important; color: #575655; background-color: #E8E6E1; padding: 3px;">
          <span style="text-decoration: none; ">
            dkam.com
          </span>
        </a>`
      }
    )
  }

  const createPhoneLink = number => {
    return(
      {__html:
        `<a href='tel:${number}' style='text-decoration: none; text-decoration: none !important; color: #575655'>
          <span style='text-decoration: none'>
            ${number}
          </span>
        </a>
        `
      }
    )
  }

  const pStyles = {
    fontWeight: '400',
    fontFamily: '"Helvetica Neue", "Helvetica", sans-serif',
    margin: '0px',
    marginBottom: '10px',
    lineHeight: '17px',
    fontSize: '13px',
    color: '#575655'
  }

  const linkStyles = {
    textDecoration: 'none',
    color: '#575655',
    backgroundColor: '#E8E6E1',
  }

  return(
    <>
      <div className="signature" ref={signatureEl}>
        <table style={{borderSpacing: '0px', width: '300px'}}>
          <tbody>
            <tr>
              <td>
                <p style={pStyles}>
                  Regards,<br/>
                  {name}<br/>
                  {title}
                </p>
                <p style={pStyles}>
                  Level 36, Governor Phillip Tower<br/>
                  1 Farrer Place Sydney NSW 2000
                </p>
                <p style={pStyles} dangerouslySetInnerHTML={createPhoneLink(phone)}></p>
                <p style={pStyles} dangerouslySetInnerHTML={createLink()}></p>
              </td>
            </tr>
            <tr>
              <td style={{width: '185px'}}>
                <img src="dkam.png" width="185" height="74" style={{width: '185px'}} alt=""/>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="button mono">
        <span onClick={e => handleButtonClick(e)} >{buttonActive ? 'Copied ✅' : 'Copy HTML to Clipboard'}</span>
      </div>
    </>
  )
}

const Dashboard = ({client, version}) => {

  const [details, setDetails] = React.useState({})

  return( details &&
    <div className="dashboard">
      <div className="dashboard__left">
        <h2>✍️ {client} Email Signature v{version}</h2>
        <Form updateDetails={setDetails}/>
        <Instructions />
      </div>
      <div className="dashboard__right">
        <h3>Preview</h3>
        <Signature name={details.name} title={details.title} phone={details.phone}/>
      </div>
    </div>
  )
}

export default Dashboard
