import React from "react"
import { Link } from "gatsby"
import SEO from "../components/seo"

import Dashboard from "../components/dashboard"

const IndexPage = () => (
  <>
  <SEO title="DKAM" />
  <Dashboard client="DKAM" version="1.0"/>
  </>
)

export default IndexPage
